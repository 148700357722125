import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Image from "reusecore/src/elements/Image";
import FeatureBlock from "../../../components/FeatureBlock";
import ParticlesComponent from "../particles";
import Fade from "react-reveal/Fade";
import Container from "../../../components/UI/Container";
import { BannerSquareShape, BannerCircleShape } from "../app.style";
import {
  DiscountWrapper,
  DiscountLabel,
  BannerImgButton
} from "./banner.style";

import bannerApp from "../../../assets/image/ride/bannerApp.png";
import bannerPlay from "../../../assets/image/ride/bannerPlay.png";
import AppScreenshot from "../../../assets/image/app/mobile.png";

const DomainSection = ({
  row,
  col,
  title,
  description,
  button,
  image,
  imageArea,
  btnStyle,
  discountAmount,
  discountText,
  SectionWrapper
}) => {
  return (
    <Box {...SectionWrapper}>
      <ParticlesComponent />
      <BannerSquareShape />
      <BannerCircleShape />
      <Container>
        <Box {...row}>
          <Box {...col}>
            <Box>
              <DiscountWrapper>
                <DiscountLabel>
                  <Text {...discountAmount} className="discountAmount" />
                  <Text {...discountText} />
                </DiscountLabel>
              </DiscountWrapper>
            </Box>
            <FeatureBlock
              title={<Heading {...title} />}
              description={<Text {...description} />}
            />
            <Fade up>
              <BannerImgButton>
                <Link href="#1">
                  <a>
                    <Image
                      src={bannerApp}
                      className="app_image_area"
                      alt="App Image"
                    />
                  </a>
                </Link>
                <Link href="#1">
                  <a>
                    <Image
                      src={bannerPlay}
                      className="play_image_area"
                      alt="GooglePlay Image"
                    />
                  </a>
                </Link>
              </BannerImgButton>
            </Fade>
          </Box>
          <Box {...col} {...imageArea}>
            <Image src={AppScreenshot} alt="Domain Image" {...image} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

DomainSection.propTypes = {
  SectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  btnStyle: PropTypes.object,
  btnStyleTwo: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  textArea: PropTypes.object
};

DomainSection.defaultProps = {
  SectionWrapper: {
    as: "section",
    pt: "80px",
    pb: "80px",
    overflow: "hidden"
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    alignItems: "center"
  },
  imageAreaRow: {
    flexDirection: "row-reverse"
  },
  col: {
    pr: "15px",
    pl: "15px",
    width: ["100%", "100%", "50%", "44%", "44%"],
    mt: "-80px"
  },
  imageArea: {
    width: ["0%", "0%", "43%", "35%", "50%"],
    ml: "auto"
  },
  title: {
    content: "HAVEACOFFEESHOP",
    fontSize: ["26px", "30px", "30px", "48px", "60px"],
    fontWeight: "300",
    color: "#0f2137",
    letterSpacing: "-0.01px",
    mb: "20px"
  },
  description: {
    content:
      "Das erste digitale Café für individualisierten und standortunabhängigen Kaffeegenuss!",
    fontSize: "16px",
    color: "#343d48",
    lineHeight: "33px",
    mb: "10px"
  },
  button: {
    title: "EXPLORE MORE",
    type: "button",
    fontSize: "14px",
    fontWeight: "600",
    color: "#fff",
    borderRadius: "4px",
    pl: "22px",
    pr: "22px",
    colors: "primaryWithBg"
  },
  image: {
    ml: "auto",
    mt: "70px"
  },
  btnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500"
  },
  btnStyleTwo: {
    title: "WATCH DEMOS",
    type: "button",
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500",
    ml: "15px",
    bg: "#fff",
    color: "#000"
  },
  textArea: {
    width: ["100%", "100%", "50%", "55%", "55%"]
  },
  discountAmount: {
    content: "update",
    fontSize: "14px",
    fontWeight: "600",
    color: "#fff",
    mb: 0,
    as: "span",
    mr: "0.4em",
    bg: "#B7742F"
  },
  discountText: {
    content: "Version 1.1.0 ist draußen!",
    fontSize: "13px",
    fontWeight: "400",
    color: "#0f2137",
    mb: 0,
    as: "span",
    ml: "10px"
  }
};

export default DomainSection;
