export const Timeline = [
  {
    description:
      "fragt den User aktiv, ob er Lust hat auf einen Kaffee, Cappuccino oder einen Snack."
  },
  {
    description:
      "zeigt dem User dann direkt die Cafés in seiner unmittelbaren Umgebung."
  },
  {
    description:
      "fragt den User nach seinem Wunschprodukt oder kennt bereits seine Favoriten."
  },
  {
    description:
      "startet den digitalen Bestellvorgang und führt den User durch den Prozess."
  },
  {
    description:
      "verbindet den User direkt mit seinem Pay-Pal-Account löst den Zahlvorgang aus und schließt den Bestellvorgang ab."
  },
  {
    description:
      "zeigt dem User wann er sein Produkt ohne Wartezeit abholen und genießen kann"
  },
  {
    description:
      "verbindet den User direkt mit der HAC-Community und bietet Social-Benefits"
  }
];
