const colors = {
  transparent: "transparent",
  labelColor: "#767676",
  inactiveField: "#f2f2f2",
  inactiveButton: "#b7dbdd",
  inactiveIcon: "#EBEBEB",
  primaryHover: "#006b70",
  secondary: "#A67C52",
  secondaryHover: "#A67C5280",
  yellow: "#fdb32a",
  yellowHover: "#F29E02",
  borderColor: "#dadada",
  black: "#000000",
  white: "#ffffff",
  primary: "#482B0A",
  headingColor: "#0f2137",
  quoteText: "#343d48",
  textColor: "rgba(52, 61, 72, 0.8)",
  linkColor: "#C17117"
};

export default colors;
