const data = {
  menuItems: [
    {
      label: "Ziele",
      path: "#goals",
      offset: "100"
    },
    {
      label: "Kundenbegeisterung",
      path: "#kundenbegeisterung",
      offset: "100"
    },
    {
      label: "Schritte",
      path: "#timeline",
      offset: "200"
    },
    {
      label: "Betreiber",
      path: "#betreiber",
      offset: "100"
    }
  ]
};
export default data;
