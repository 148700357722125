import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Logo from "reusecore/src/elements/UI/Logo";
import Container from "../../../components/UI/Container";
import FooterWrapper, { List, ListItem } from "./footer.style";
import LogoImage from "../../../assets/image/app/logo.png";

import data from "../../../data/App/Footer";

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle,
  copyrightMenu,
  copyright
}) => {
  return (
    <FooterWrapper>
      <Container>
        <Box className="row" {...row}>
          {/* End of footer logo column */}
          {/* <Box {...colOne}>
            {data.menuWidget.map(widget => (
              <Box className="col" {...col} key={widget.id}>
                <Heading content={widget.title} {...titleStyle} />
                <List>
                  {widget.menuItems.map(item => (
                    <ListItem key={`list__item-${item.id}`}>
                      <Link href={item.url}>
                        <a className="ListItem">{item.text}</a>
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box> */}
          <Box {...colTwo} className="copyrightClass">
            <Logo
              href="#"
              logoSrc={LogoImage}
              title="Agency"
              logoStyle={logoStyle}
            />
            <Box {...copyrightMenu} className="copyrightMenu">
              <Link to="/">
                <Text content="Home" {...textStyle} />
              </Link>
              <Link to="/impressum">
                <Text content="Impressum" {...textStyle} />
              </Link>
              <Link to="/datenschutz">
                <Text content="Datenschutz" {...textStyle} />
              </Link>
            </Box>
            <Box {...copyright} className="copyrightText">
              <Text content="copyright 2019 Haveacoffeeshop" {...textStyle} />
            </Box>
          </Box>
          {/* End of footer List column */}
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px"
  },
  // Footer col one style
  colTwo: {
    mt: [0, "13px"],
    mb: ["0px", 0],
    pl: ["15px", 0],
    pt: ["35px", "55px"],
    pr: ["15px", "15px", 0],
    borderTop: "1px solid",
    borderColor: "rgba(0,0,0,0.102)",
    flexBox: true,
    flexWrap: "wrap",
    width: ["100%"]
  },
  // Footer col two style
  colOne: {
    width: ["100%"],
    flexBox: true,
    flexWrap: "wrap"
  },
  // Footer col default style
  col: {
    width: ["100%", "50%", "50%", "25%", "25%"],
    pl: ["15px", "0px"],
    pr: ["15px", "0px"],
    mb: "30px"
  },
  // widget title default style
  titleStyle: {
    color: "#343d48",
    fontSize: "16px",
    fontWeight: "700"
  },
  // Default logo size
  logoStyle: {
    width: "auto",
    maxWidth: "50px",
    height: "auto",
    mb: ["15px", 0]
  },
  // widget text default style
  textStyle: {
    color: "#20201d",
    fontSize: "14px",
    mb: "10px",
    mr: "30px"
  },
  copyrightMenu: {
    flexBox: true,
    flexWrap: "wrap",
    ml: [0, "40px"],
    mt: "3px",
    fontWeight: "500",
    justifyContent: "center",
    alignItems: "center",
    mb: ["15px", 0]
  },
  copyright: {
    ml: [0, 0, 0, "auto", "auto"],
    color: "#20201d",
    fontSize: "14px",
    mb: "10px",
    mt: "3px",
    fontWeight: "500",
    justifyContent: "center",
    alignItems: "center"
  }
};

export default Footer;
