import React from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Card from "reusecore/src/elements/Card";
import Image from "reusecore/src/elements/Image";
import FeatureBlock from "../../../components/FeatureBlock";
import AboutUsSectionWrapper from "./aboutUsSection.style";
import Container from "../../../components/UI/Container";

import data from "../../../data/App/Betreiber";

import GroupImage1 from "../../../assets/image/app/group-image1.jpg";
import GroupImage2 from "../../../assets/image/app/group-image2.jpg";
import GroupImage3 from "../../../assets/image/app/group-image3.jpg";

const AboutUsSection = ({
  row,
  col,
  title,
  description,
  textArea,
  featureTitle,
  btnStyle,
  secTitle,
  secDescription,
  secTitleWrapper
}) => {
  return (
    <AboutUsSectionWrapper id="betreiber">
      <Container noGutter mobileGutter width="1200px" className="container">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content="Was steckt für die Betreiber drin?" />
          <Text
            {...secDescription}
            content="Von der einfachen Reprästenation bis zur Prozessoptmierung."
          />
        </Box>
      </Container>
      <Box className="row" {...row}>
        <Box className="col" {...col}>
          <Card className="group-gallery">
            <Box className="col1">
              <Fade top delay={30}>
                <Image src={GroupImage1} alt="Feature Image" />
              </Fade>
              <Fade left delay={60}>
                <Image src={GroupImage3} alt="Feature Image" />
              </Fade>
            </Box>
            <Box className="col2">
              <Fade bottom delay={90}>
                <Image src={GroupImage2} alt="Feature Image" />
              </Fade>
            </Box>
          </Card>
        </Box>
        <Box className="col" {...col}>
          <Box {...textArea}>
            {data.aboutus.map((feature, index) => (
              <FeatureBlock
                key={`feature_point-${index}`}
                icon={<i className={feature.icon} />}
                iconPosition="left"
                title={<Heading content={feature.title} {...featureTitle} />}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </AboutUsSectionWrapper>
  );
};

AboutUsSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object
};

AboutUsSection.defaultProps = {
  // About us section row default style
  row: {
    flexBox: true,
    flexWrap: "wrap"
  },
  // About us section col default style
  col: {
    width: [1, "100%", "50%"]
  },
  // About us section text area default style
  textArea: {
    maxWidth: "490px",
    pl: "40px"
  },
  // About us section title default style
  title: {
    fontSize: ["26px", "26px", "30px", "40px"],
    lineHeight: "1.5",
    fontWeight: "300",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "30px"
  },
  // About us section description default style
  description: {
    fontSize: "16px",
    color: "#343d48cc",
    lineHeight: "1.75",
    mb: "33px"
  },

  // feature title default style
  featureTitle: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#343d48",
    lineHeight: "1.5",
    mb: "8px",
    letterSpacing: "-0.020em"
  },
  // Button default style
  btnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500"
  },
  secTitleWrapper: {
    mb: ["65px", "65px", "80px", "90px", "90px"]
  },
  secTitle: {
    fontSize: ["22px", "26px", "26px", "30px", "36px"],
    fontWeight: "600",
    color: "#15172C",
    lineHeight: "1.34",
    mb: ["15px", "18px", "18px", "20px", "30px"],
    textAlign: "center",
    fontFamily: "Poppins"
  },
  secDescription: {
    fontSize: ["15px", "16px"],
    fontWeight: "400",
    color: "#15172C",
    lineHeight: "1.5",
    mb: "0",
    textAlign: "center",
    width: "300px",
    maxWidth: "100%",
    ml: "auto",
    mr: "auto",
    fontFamily: "Lato"
  }
};

export default AboutUsSection;
