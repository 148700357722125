import React, { useContext } from "react";
import PropTypes from "prop-types";
import NavbarWrapper from "reusecore/src/elements/Navbar";
import Drawer from "reusecore/src/elements/Drawer";
import Logo from "reusecore/src/elements/UI/Logo";
import HamburgMenu from "../../../components/HamburgMenu";
import ScrollSpyMenu from "../../../components/ScrollSpyMenu";
import { Container } from "./navbar.style";
import { Link } from "gatsby";
import LogoImage from "../../../assets/image/app/logo.png";

import { DrawerContext } from "../../../contexts/DrawerContext";

import data from "../../../data/App/MenuItems";

const Navbar = ({
  navbarStyle,
  logoStyle,
  extraLinks,
  deactivated,
  linkStyle
}) => {
  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: "TOGGLE"
    });
  };

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Logo
          href="#"
          logoSrc={LogoImage}
          title="Agency"
          logoStyle={logoStyle}
        />
        {extraLinks &&
          extraLinks.map(site => {
            return (
              <Link to={`${site.to}`}>
                <h3 style={linkStyle}>{site.title}</h3>
              </Link>
            );
          })}

        {!deactivated && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                menuItems={data.menuItems}
                drawerClose={true}
                offset={-100}
              />
            </Drawer>
          </div>
        )}
      </Container>
    </NavbarWrapper>
  );
};

// Navbar style props
Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  wrapperStyle2: PropTypes.object,
  extraLinks: PropTypes.array,
  deactivated: PropTypes.bool
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: "70px"
  },
  logoStyle: {
    width: ["80px", "100px"]
  },
  buttonStyle: {
    minHeight: "70px",
    color: "#fff"
  },
  linkStyle: {
    color: "#333"
  }
};

export default Navbar;
