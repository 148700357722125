const data = {
  aboutus: [
    {
      id: 1,
      title: "Steigerung der Bekanntheit und Wahrnehmung",
      icon: "flaticon-next"
    },
    {
      id: 2,
      title:
        "Zusätzliche Kundengewinnung, Reduzierung von Leerzeiten und Umsatzsteigerung",
      icon: "flaticon-next"
    },
    {
      id: 3,
      title:
        "Differenzierung von anderen Café-Betreibern und Schaffung eines Wettbewerbsvorteils",
      icon: "flaticon-next"
    },
    {
      id: 4,
      title: "Optimierung des Verkaufsprozesses und der Zahlungsabwicklung",
      icon: "flaticon-next"
    },
    {
      id: 5,
      title: "Teil einer digitalen Qualitäts-To-Go-Community sein",
      icon: "flaticon-next"
    }
  ]
};
export default data;
