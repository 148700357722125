import React, { useState, useEffect, Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Sticky from "react-stickynode";
import { appTheme } from "common/src/theme/app";
import { GlobalStyle, AppWrapper } from "common/src/containers/App/app.style";
import { ResetCSS } from "common/src/assets/css/style";
import Navbar from "common/src/containers/App/Navbar";
import DomainSection from "common/src/containers/App/Banner";
import Footer from "common/src/containers/App/Footer";
import FeatureSliderN from "common/src/containers/App/FeatureSliderN";
import { DrawerProvider } from "common/src/contexts/DrawerContext";
import RideOption from "common/src/containers/App/RideOption";
import AboutUsSection from "common/src/containers/App/AboutUsSection";
import TimelineSection from "common/src/containers/App/TimelineSection";
import PaymentSection from "common/src/containers/App/PaymentSection";
import "@redq/reuse-modal/es/index.css";
import SEO from "../components/seo";

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth
  };
}

function useWindowSize() {
  let [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
}

export default () => {
  const size = process.browser && useWindowSize();
  const innerWidth = process.browser && size.innerWidth;

  return (
    <ThemeProvider theme={appTheme}>
      <Fragment>
        <SEO
          title="Haveacoffeeshop | shop where you are"
          metaDescription="A digital coffeeshop finder and online product ordering service brought to mobile devices."
        />
        <ResetCSS />
        <GlobalStyle />
        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <DomainSection />
          <RideOption />
          <FeatureSliderN />
          <TimelineSection />
          <AboutUsSection />
          <PaymentSection />
          <Footer />
        </AppWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
